import React, { useEffect, useState } from "react";
import logo from "../images/virtualreel-logo.png";
import headerBg from "../images/header-bg.png";
import caret from "../images/caret-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logoutUser } from "../actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey, faUsers, faPlusSquare, faHeadset, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"


const Header = () => {

    const rebrandData = useSelector(state => state.rebrand)
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [user, setUser] = useState(undefined);
    const [memberships, setMemberships] = useState([]);

    const handleLogout = () => {
        dispatch(logoutUser());
    }

    useEffect(() => {
        if (auth.user !== null) {
            setUser(auth.user);

            if (auth.user.membership !== '' && auth.user.membership !== null) {
                let memLevels = auth.user.membership.split('__');
                setMemberships(memLevels);
            }
        }
    }, [auth.user])

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };


    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 350);
        });
    }, []);

    return (
        <>
            <header id="header" className={scroll ? "header fixed" : "header"} style={{ background: `url(${headerBg})` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="colmn colmn-1">
                            <div className="img-wrapper topLogo" style={{ width: "300px", height: "65px" }}>
                                <Link to={'/'}>
                                    <img className="img-fluid" style={{ height: "100%" }} src={rebrandData.data !== false ? rebrandData.data.logo : logo} alt="Virtual Logo" />
                                </Link>
                            </div>
                        </div>

                        <div className="colmn colmn-2" >
                            <div className={isActive ? "headM" : "headM act"}>
                                <ul className="list d-flex align-items-center justify-content-between">
                                    <li className="md-txt">
                                        <NavLink to={`/dashboard`} className="nav-link" activeClassName="active">Dashboard</NavLink>
                                    </li>
                                    <li className="md-txt">
                                        <NavLink to={`/projects`} className="nav-link" activeClassName="active">Projects</NavLink>
                                    </li>

                                    {
                                        user !== undefined && user.is_client_account === "0" ?
                                            <li className="md-txt">
                                                <NavLink to={`/integration`} className="nav-link" activeClassName="active">Integrations</NavLink>
                                            </li>
                                            : ''
                                    }

                                    <li className="md-txt">
                                        <NavLink to={`/help-and-support`} className="nav-link" activeClassName="active">Help</NavLink>
                                    </li>

                                    <li>
                                        <div className="in-in-colmn in-in-colmn-2 d-flex align-items-center"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <div className="user-profile-img img-wrapper">
                                                <img className="img-fluid" width="50px" src={auth?.user?.image} alt="Dummy" />
                                            </div>

                                            <div className="user-info">
                                                <p className="fs-15" style={{ color: "#00DCFF" }}>Welcome</p>
                                                <p className="md-txt text-white authP">{auth?.user?.name}</p>
                                            </div>

                                            <div className="img-wrapper">
                                                <img className="img-fluid" src={caret} alt="Caret Icon" />
                                            </div>
                                        </div>

                                        <div className={`dropdown-menu user-row`} aria-labelledby="dropdownMenuButton">

                                            {
                                                user !== undefined && user.is_client_account === "0" ?
                                                    <>
                                                        <Link to={`/profile`} className="dropdown-item fb" >
                                                            <span className="mr-2">
                                                                <FontAwesomeIcon icon={faUser} />
                                                            </span>My Account</Link>
                                                        {
                                                            user !== undefined && user.is_client_account === "0" && user.is_virtual_account === "0" ?
                                                                <Link to={`/privacy`} className="dropdown-item fb" >
                                                                    <span className="mr-2">
                                                                        <FontAwesomeIcon icon={faKey} />
                                                                        <i className="fab fa-key"></i>
                                                                    </span>
                                                                    Privacy
                                                                </Link>
                                                                : ""
                                                        }


                                                    </>
                                                    : ''
                                            }
                                            {/* {
                                                user !== undefined && user.is_client_account === "0" && user.is_virtual_account === "1" ?
                                                    <>
                                                        <Link to={`/profile`} className="dropdown-item fb" >
                                                            <span className="mr-2">
                                                                <FontAwesomeIcon icon={faUser} />
                                                            </span>My Account</Link>


                                                    </>
                                                    : ''
                                            } */}

                                            {
                                                user !== undefined && user.is_client_account === "0" && user.is_virtual_account === "0" && memberships.includes('business') ?
                                                    <Link to={`/account-management`} className="dropdown-item fb">
                                                        <span className="mr-2">
                                                            <FontAwesomeIcon icon={faUsers} />
                                                        </span>Account Management
                                                    </Link>
                                                    : ''
                                            }

                                            {
                                                user !== undefined && user.is_client_account === "0" ?
                                                    <Link to={`/upgrades`} className="dropdown-item fb" >
                                                        <span className="mr-2">
                                                            <FontAwesomeIcon icon={faPlusSquare} />
                                                        </span>Upgrades
                                                    </Link>
                                                    : ''
                                            }

                                            <Link to={`/help-and-support`} className="dropdown-item fb" >
                                                <span className="mr-2">
                                                    <FontAwesomeIcon icon={faHeadset} />
                                                </span>Help & Support
                                            </Link>
                                            <button onClick={() => handleLogout()} className="dropdown-item fb cursor-pointer" >
                                                <span className="mr-2">
                                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                                </span> Logout
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>


                            <a className="burger-icon" onClick={handleToggle}>
                                <div className="burger">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="#ffffff" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}


export default React.memo(Header);