import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import './css/style.css';
import './css/responsive.css';
import MetaVerse from "./MetaVerse";
import TextStyles from "./TextStyles";
import MediaLibrary from "./MediaLibrary";
import CharacterLibrary from "./CharacterLibrary";
import AudioLibrary from "./AudioLibrary";
import IntroOutro from "./IntroOutro";

import mvIcon from "../../images/mv-lib-icon.svg";
import textIcon from "../../images/text-icon.svg";
import mediaIcon from "../../images/med-lib-icon.svg";
import charIcon from "../../images/char-lib-icon.svg";
import audioIcon from "../../images/audio-icon.svg";
import introIcon from "../../images/in-out-icon.svg";
import Preview from "./Preview";
import VideoTiles from "./VideoTiles";
import EditorHead from "./EditorHead";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import axios from "axios";
import { baseURL } from "../../global/global";
import { addCampaignData } from "../../actions/editorAction";
import { setAlert } from "../../actions/alert";
import Settings from "./Settings";
import { removeTimelineData, replaceTimelineLayer } from "../../actions/timelineAction";
import Header from "../Header";
import TextPreivew from "./TextPreivew";
import PreviewIntroOutro from "./PreviewIntroOutro";

const Editor = ({ location }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const loadBackground = useSelector(state => state.script.current_load);

    const currentLayer = useSelector(state => state.currentLayer.data);
    const campName = useSelector(state => state.currentLayer.data.campaign_type);
    const timelineLayer = useSelector(state => state.timeline.data);
    const id = queryString.parse(location.search).id;

    const [pageLoader, setPageLoader] = useState(true);
    const [canvasImgData, setCanvasImgData] = useState('');
    const [canData, setCanData] = useState(null);

    const handleSave = () => {
        console.log('saving...');
    }


    useEffect(() => {
        if (id !== undefined) {
            setPageLoader(true);

            axios({
                method: 'POST',
                url: `${baseURL}edit-project`,
                data: { project_id: id },
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then(res => {
                if (res.data.status === true) {
                    dispatch(addCampaignData(res.data.data));
                    dispatch(replaceTimelineLayer(res.data.datalayer))
                } else {
                    dispatch(setAlert(res.data.message, 'danger'))
                }
                setPageLoader(false);
            }).catch(err => {
                setPageLoader(false);
            })
        }

        return () => {
            dispatch(removeTimelineData());
        }

    }, [id])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Editor </title>
            </Helmet>

            {
                pageLoader === false ?
                    <main className="editor-main">
                        <Header />

                        <section className="editor-sec">
                            <div className="row editor-container m-0">
                                <div className="colmn colmn-1 order-lg-1 order-2 p-0">
                                    <div className="editor-part">
                                        <div className="tab-content text-white" id="pills-tabContent">

                                            <div className="tab-pane editor-panel fade show active" id="pills-mv" role="tabpanel" aria-labelledby="pills-mv-tab" style={{ paddingBottom: "130px" }}>
                                                <MetaVerse />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-text" role="tabpanel" aria-labelledby="pills-text-tab" style={{ paddingBottom: "130px" }}>
                                                <TextStyles />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-media" role="tabpanel" aria-labelledby="pills-media-tab" style={{ paddingBottom: "130px" }}>
                                                <MediaLibrary />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-char-lib" role="tabpanel" aria-labelledby="pills-char-lib-tab" style={{ paddingBottom: "130px" }}>
                                                <CharacterLibrary />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-audio" role="tabpanel" aria-labelledby="pills-audio-tab" style={{ paddingBottom: "130px" }}>
                                                <AudioLibrary />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-in-out" role="tabpanel" aria-labelledby="pills-in-out-tab" style={{ paddingBottom: "130px" }}>
                                                <IntroOutro key={1} />
                                            </div>

                                            <div className="tab-pane editor-panel fade" id="pills-in-setting" role="tabpanel" aria-labelledby="pills-in-setting-tab" style={{ paddingBottom: "130px" }}>
                                                <Settings type={currentLayer.campaign_type} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colmn colmn-2 order-lg-2 order-1 p-0">
                                    <div className="colmn-preview-wrapper">
                                        <div className="save-render-sec" style={{ background: '#293758', borderRadius: '0px' }}>
                                            <EditorHead canvasImgData={canvasImgData} handleSave={handleSave} />
                                        </div>
                                        <div className="editor-tab-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="editor-tab-menu">
                                                        <ul className="nav d-flex justify-content-center" id="pills-tab" role="tablist">
                                                            <li className="nav-item text-center  mb-2">
                                                                <a className="nav-link active" id="pills-mv-tab" data-toggle="pill" href="#pills-mv" role="tab" aria-controls="pills-mv" aria-selected="true">
                                                                    <div className="img-wrapper">
                                                                        <img src={mvIcon} alt="Meta verse Library" className="img-fluid" />
                                                                    </div>
                                                                    <h6>VR Library</h6>
                                                                </a>
                                                            </li>

                                                            {
                                                                currentLayer.campaign_type !== 'campaign_three' ?
                                                                    <li className="nav-item text-center">
                                                                        <a className="nav-link" id="pills-text-tab" data-toggle="pill" href="#pills-text" role="tab" aria-controls="pills-text" aria-selected="false">
                                                                            <div className="img-wrapper">
                                                                                <img src={textIcon} alt="Text" className="img-fluid" />
                                                                            </div>
                                                                            <h6>Text</h6>
                                                                        </a>
                                                                    </li>
                                                                    : ''
                                                            }

                                                            <li className="nav-item text-center">
                                                                <a className="nav-link" id="pills-media-tab" data-toggle="pill" href="#pills-media" role="tab" aria-controls="pills-media" aria-selected="false">
                                                                    <div className="img-wrapper">
                                                                        <img src={mediaIcon} alt="Media Library" className="img-fluid" />
                                                                    </div>
                                                                    <h6>Media Library</h6>
                                                                </a>
                                                            </li>
                                                            {
                                                                currentLayer.campaign_type !== 'campaign_three' && currentLayer.campaign_type !== 'campaign_two' ?
                                                                    <li className="nav-item text-center">
                                                                        <a className="nav-link" id="pills-char-lib-tab"
                                                                            data-toggle="pill" href="#pills-char-lib"
                                                                            role="tab" aria-controls="pills-char-lib"
                                                                            aria-selected="false">
                                                                            <div className="img-wrapper">
                                                                                <img src={charIcon} alt="Character Library"
                                                                                    className="img-fluid" />
                                                                            </div>
                                                                            <h6>Character Library</h6>
                                                                        </a>
                                                                    </li>
                                                                    : ''
                                                            }
                                                            <li className="nav-item text-center">
                                                                <a className="nav-link" id="pills-audio-tab" data-toggle="pill" href="#pills-audio" role="tab" aria-controls="pills-audio" aria-selected="false">
                                                                    <div className="img-wrapper">
                                                                        <img src={audioIcon} alt="Audio" className="img-fluid" />
                                                                    </div>
                                                                    <h6>Audio</h6>
                                                                </a>
                                                            </li>
                                                            {
                                                                currentLayer.campaign_type !== 'campaign_four' ?
                                                                    <li className="nav-item text-center">
                                                                        <a className="nav-link" id="pills-in-out-tab" data-toggle="pill" href="#pills-in-out" role="tab" aria-controls="pills-in-out" aria-selected="false">
                                                                            <div className="img-wrapper">
                                                                                <img src={introIcon} alt="Intro/outro" className="img-fluid" />
                                                                            </div>
                                                                            <h6>Intro/outro</h6>
                                                                        </a>
                                                                    </li>
                                                                    : ''
                                                            }
                                                            <li className="nav-item text-center mt-sm-0 mt-2">
                                                                <a className="nav-link" id="pills-in-setting-tab" data-toggle="pill" href="#pills-in-setting" role="tab" aria-controls="pills-in-setting" aria-selected="false">
                                                                    <div className="img-wrapper">
                                                                        <img src={introIcon} alt="Intro/outro" className="img-fluid" />
                                                                    </div>
                                                                    <h6>Settings</h6>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="editor-preview mx-auto">

                                            {
                                                loadBackground === 'intro' || loadBackground === 'outro' ?
                                                    <PreviewIntroOutro type={loadBackground} />
                                                    :
                                                    campName === 'campaign_two' ?
                                                        <TextPreivew
                                                            currentLayer={currentLayer}
                                                        />
                                                        :
                                                        <Preview
                                                            currentLayer={currentLayer}
                                                            timelineLayer={timelineLayer}
                                                            setCanvasImgData={setCanvasImgData}
                                                            campaign_type={campName}
                                                            setCanData={setCanData}
                                                        />
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <VideoTiles />

                    </main>
                    :
                    <div className="loader-sec">
                        <div className="loader"></div>
                    </div>
            }

        </>
    )
}

export default Editor;