import './App.css'
import "../src/css/hover.css";
import '../src/css/header.css';
import '../src/css/footer.css';
import '../src/css/default-style.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, onFetchFont } from "./actions/authAction";
import { PrivateRoute } from "./components/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/user/Profile";
import HelpSupport from "./components/help/HelpSupport";
import Training from "./components/help/Training";
import TrainingArticle from "./components/help/TrainingArticle";
import Editor from "./components/editor/Editor";
import Privacy from "./components/user/Privacy";
import CreateMetaVerse from "./components/dashboard/CreateMetaVerse";
import Integration from "./components/Integration/Integration";
import AccountManagement from "./components/user/AccountManagement";
import CreateMetaText from "./components/dashboard/CreateMetaText";
import Projects from "./components/project/Projects";
import Upgrades from "./components/upgrades/Upgrades";
import PanolensContainer from "./components/editor/PanolensContainer";
import UploadRegularVideo from "./components/dashboard/UploadRegularVideo";
import MasterLogin from "./components/MaterLogin";
import { fontNotSet } from './global/FontFamilyData';

function App() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert)


    useEffect(() => {
        dispatch(loadUser())
    }, [])

    // useEffect(() => {
    //     if (alert !== undefined) {
    //         if (alert.message !== "") {
    //             setTimeout(() => {
    //                 dispatch(removeAlert())
    //             }, 4000);   
    //         }
    //     }
    // }, [alert, dispatch])
    useEffect(() => (
        dispatch(loadUser())
    ), [])
    useEffect(() => {
        dispatch(onFetchFont(fontNotSet))
    }, [])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/training" component={Training} />
                    <PrivateRoute exact path="/training-article" component={TrainingArticle} />

                    <PrivateRoute exact path="/projects" component={Projects} />
                    <PrivateRoute exact path="/script" component={CreateMetaText} />
                    <PrivateRoute exact path="/editor" component={Editor} />
                    <PrivateRoute exact path="/create-metaverse" component={CreateMetaVerse} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/regular-video" component={UploadRegularVideo} />


                </Switch>
            </Router>
        </div>
    );
}

export default App;
