import React, { useState } from "react";
import banner from "../../images/banner-bg.jpg";
import passwordIcon from "../../images/password-icon.svg";
import Footer from "../Footer";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Alert from "../Alert";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../actions/authAction";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/virtualreel-logo.png";

const ResetPassword = ({ location }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: '',
        confirm_password: '',
        password_token: queryString.parse(location.search).token
    });
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    });
    const [validationErrors, setValidationErrors] = useState([]);

    const handleInputChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };

    const validatePassword = (password) => {
        const errors = [];
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;

        if (!pattern.test(password)) {
            errors.push("Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character, and be at least 8 characters long.");
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validatePassword(password.password);
        if (errors.length > 0) {
            setValidationErrors(errors);
            setPasswordMsg({ ...passwordMsg, msg: "", validation: false });
            return;
        }

        if (password.password === password.confirm_password) {
            setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true });
            setLoader(true);
            dispatch(resetPassword(password, setLoader, history));
        } else {
            setPasswordMsg({ ...passwordMsg, msg: "Passwords do not match!", validation: true });
        }
    };

    return (
        <>
            <Helmet>
                <title>VirtualReel | Reset Password </title>
            </Helmet>
            <Alert />
            <div className="login-page-body">
                <main className="login-page-main">
                    <section id="banner" className="banner flex-elem" style={{ background: `url(${banner})` }}>
                        <div className="custom-wrapper container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6 col-12 colmn colmn-1">
                                    <div className="title-area mb-3">
                                        <div className="dash-logo"><img src={logo} alt={"logo"} /> </div>
                                        <h2 className="f-300">WELCOME TO</h2>
                                        <h1><span className="f-800">Virtual</span>Reel</h1>
                                    </div>
                                    <div className="text-wrapper alt">
                                        <p className="md-txt f-300">Effortlessly create 3D Metaverse videos in a few clicks. Turn your regular videos, text, or articles into full-blown 360 Degree VR Videos in any niche.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 colmn colmn-2">
                                    <h3 className="f-600 mb-4"><span style={{ color: "#00D47F" }}>Reset</span> Password</h3>
                                    <form method="POST" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="password" className="md-txt text-white">New Password</label>
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper email-icon">
                                                    <img className="img-fluid" src={passwordIcon} alt="Password icon" />
                                                </div>
                                                <input type="password" className="password" name="password" onChange={handleInputChange} placeholder="*************" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirm_password" className="md-txt text-white">Confirm Password</label>
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper email-icon">
                                                    <img className="img-fluid" src={passwordIcon} alt="Password icon" />
                                                </div>
                                                <input type="password" className="password" name="confirm_password" onChange={handleInputChange} placeholder="*************" autoComplete="off" />
                                            </div>
                                        </div>
                                        {validationErrors.length > 0 && (
                                            <ul className="validation-errors">
                                                {validationErrors.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                        <button disabled={loader} type="submit" className="md-txt f-600 cursor-pointer">
                                            {loader ? <FontAwesomeIcon icon={faSpinner} spin /> : 'RESET PASSWORD'}
                                        </button>
                                    </form>
                                    <p className="fs-15 text-center mt-4">Have a Login? <Link to={`/login`}> Click here</Link></p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
            </div>
        </>
    );
};

export default ResetPassword;
