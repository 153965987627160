import React, { useState } from "react";
import banner from "../../images/banner-bg.jpg";
import emailIcon from "../../images/email-icon.svg";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/virtualreel-logo.png";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: ''
    })

    const handleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(forgetPassword(user, setLoader));
    }

    return (
        <>
            <Helmet>
                <title>VirtualReel | Forgot Password </title>
            </Helmet>
            <Alert />
            <div className="login-page-body">
                <main className="login-page-main">
                    <section id="banner" className="banner flex-elem" style={{ background: `url(${banner})` }}>
                        <div className="custom-wrapper container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6 col-12 colmn colmn-1">
                                    <div className="title-area mb-3">
                                        <div className="dash-logo"><img src={logo} alt={"logo"} /> </div>
                                        {/* <h2 className="f-800" style={{color: "#00FF99"}}>WELCOME TO</h2>
                                        <p className="h1 f-700">VirtualReel</p> */}
                                        <h2 className="f-300">WELCOME TO</h2>
                                        <h1><span className="f-800">Virtual</span>Reel</h1>
                                    </div>

                                    <div className="text-wrapper alt">
                                        <p className="md-txt f-300">Effortlessly create 3D Metaverse videos in a few clicks. Turn your regular videos, text, or articles into full-blown 360 Degree VR Videos in any niche.</p>
                                    </div>

                                    {/* <div className="text-wrapper text-center">
                                        <p className="md-txt text-center">Effortlessly create 3D Metaverse videos in a few clicks. Turn
                                            your
                                            regular videos, text, or articles into full-blown 360 Degree VR Videos in any niche.</p>
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-12 colmn colmn-2">
                                    <h3 className="f-600 mb-4"><span style={{ color: "#00D47F" }}>Enter</span> Your Email Address
                                    </h3>

                                    <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                                        <div className="form-group">
                                            <label htmlFor="email" className="md-txt text-white">Email Address</label>

                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper email-icon">
                                                    <img className="img-fluid" src={emailIcon} alt="Email icon" />
                                                </div>

                                                <input type="email" className="email" name="email"
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Email Address"
                                                    autoComplete="off" />
                                            </div>
                                        </div>

                                        <button disabled={loader} type="submit" className="md-txt f-600 cursor-pointer">
                                            {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} SUBMIT
                                        </button>
                                    </form>

                                    <p className="fs-15 text-center mt-4">Have a Login?
                                        <Link to={`/login`}> Click here</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main>
            </div>
        </>
    )
}

export default ForgetPassword;